/* テーブル全体のスタイル */
.tableContainer {
  margin-top: 20px;
}

.table {
  min-width: 650px;
  border-collapse: collapse;
  background-color: #f9f9f9;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

/* テーブルヘッダーのスタイル */
.tableHeader {
  background-color: #f5f5f5;
  font-weight: bold;
}
  
  /* テーブルヘッダーセルのスタイル */
  .tableHeaderCell {
    padding: 10px;
    text-align: right;
  }
  
  /* テーブルボディのスタイル */
  .tableBody {
    font-size: 14px;
  }
  
  /* 偶数行のスタイル */
  .tableRow:nth-child(even) {
    background-color: #e8e8e8;
  }
  
  /* テーブルセルのスタイル */
  .tableCell {
    padding: 10px;
    text-align: right;
  }
  
  /* 編集ボタンのスタイル */
  .editButton {
    background-color: #3498db;
    color: #fff;
    border: none;
    padding: 5px 10px;
    border-radius: 3px;
    cursor: pointer;
  }
  
  /* リンクのスタイル */
  .link {
    color: #3498db;
    text-decoration: none;
  }
  
  .link:hover {
    text-decoration: underline;
  }
