/* design_pagination.css */

.pagination {
    display: flex;
    justify-content: center;
    list-style: none;
    padding: 0;
  }
  
  .page-item {
    margin: 0 5px;
  }
  
  .page-link {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #eee;
    color: #333;
    text-decoration: none;
    transition: background-color 0.3s ease;
  }
  
  .page-link:hover {
    background-color: #ccc;
  }
  
  .active .page-link {
    background-color: #333;
    color: #fff;
  }
  
  .previous-link,
  .next-link {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #eee;
    color: #333;
    text-decoration: none;
    transition: background-color 0.3s ease;
  }
  
  .previous-link:hover,
  .next-link:hover {
    background-color: #ccc;
  }
  
  .disabled-button {
    display: none;
  }
  
  .break-link {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: transparent;
    color: #333;
    text-decoration: none;
  }
  