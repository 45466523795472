.sensor-info-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    background-color: #F2F5FA;
    border: 1px solid #C8D2E3;
    border-radius: 5px;
    padding: 20px;
  }
  
  .sensor-info-item {
    flex-basis: 23%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .sensor-info-label {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  .sensor-info-value {
    padding: 10px;
    background-color: transparent;
    border-radius: 5px;
    border: 1px solid #E4E8F2;
    color: #333;
  }


  .loading-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100px;
  }
  
  .loading-spinner {
    width: 40px;
    height: 40px;
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-left-color: #3f51b5;
    border-radius: 50%;
    animation: loading-rotate 1s linear infinite;
  }
  
  @keyframes loading-rotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }